const APIList = [
  {
    "name": "Cricket Live Score",
    "docs": "https://rapidapi.com/sudityakumar1/api/free-cricket-live-score1",
    "slug": "/api/cricket-live-score",
    "image_url": "/images/cricket.webp",
    "description": "Elevate your cricket-watching experience with our Cricket Live Score API, a powerful tool that provides real-time live scores for....."
  },
  {
    "name": "Betfair Betting API",
    "docs": "https://rapidapi.com/scoreswift-scoreswift-default/api/betfair14",
    "slug": "/api/betting-api",
    "image_url": "/images/betfair.png",
    "description": "Introducing the pinnacle of online betting experiences: our state-of-the-art Betting API, designed to revolutionize the way you engage with sports betting. Crafted with precision and powered by cutting-edge technology, our platform mirrors the renowned features and functionalities of industry titan Betfair, ensuring an unparalleled betting journey for enthusiasts worldwide."
  },
  {
    "name": "Football Live Score",
    "docs": "https://rapidapi.com/sudityakumar1/api/free-football-live-score",
    "slug": "/api/football-live-score",
    "image_url": "/images/football.png",
    "description": "Let’s delve into a comprehensive description of your Football Live Score API. This API provides real-time and low-latency updates for football enthusiasts, ensuring they stay up-to-date with the latest match developments."
  },
  {
    "name": "All Sports Live Stream",
    "docs": "https://rapidapi.com/scoreswift-scoreswift-default/api/all-sport-live-stream",
    "slug": "/api/all-sport-live-stream",
    "image_url": "/images/live.jpg",
    "description": "Get live stream of more than 120 sports. We have Live Stream of Cricket, Football, Tennis, Basketball, Volleyball, Hockey, Golf, Rugby, and many more."
  },
  {
    "name": "Diamond Exchange API",
    "docs": "https://rapidapi.com/scoreswift-scoreswift-default/api/diamond-betting-api",
    "slug": "/api/diamond-betting-api",
    "image_url": "/images/diamond-exch.png",
    "description": "Get the Diamond Exchange API to bet on the highest odds. This API provides real-time and low-latency updates for diamond enthusiasts, ensuring they stay up-to-date with the latest match developments."
  },
  {
    "name": "Fantasy Cricket API",
    "docs": "https://rapidapi.com/scoreswift-scoreswift-default/api/fantasy-cricket-api",
    "slug": "/api/fantasy-cricket-api",
    "image_url": "/images/fantasy-cricket-application.jpg",
    "description": "Unlock the ultimate cricketing experience with our robust Fantasy Cricket API. Designed for developers, startups, and established platforms, this API delivers real-time and detailed cricket data to power your fantasy cricket applications."
  }
]

const APIFeatures = {
  "cricket-live-score": [
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "100 ms latency",
      "description": "This api has ~150 ms average latency."
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "Live Score",
      "description": "This API provides ball-by-ball commentary, scorecard, linup and match info."
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "Live Stream",
      "description": "This API also provides live stream of every single match."
    },
    {
      "img": "https://www.svgrepo.com/show/530440/machine-vision.svg",
      "heading": "Odds Score",
      "description": "This API also provides Odds Score and Historical Odds Score of every single match"
    },
    {
      "img": "https://www.svgrepo.com/show/530450/page-analysis.svg",
      "heading": "Schedule",
      "description": "This API also provides Schedule of 1 year match upcoming match"
    },
    {
      "img": "https://www.svgrepo.com/show/530453/mail-reception.svg",
      "heading": "Player Stats",
      "description": "This API also provides player's up-to-date career stats"
    },
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "Series ",
      "description": "This API provides every single info of series like points table, fixtures, team, stats, and many other info"
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "Ranking",
      "description": "This API provides ranking of men, women and teams they are categorised on the basis of match formate."
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "News",
      "description": "This API also provides latest news as well."
    },
  ],

  "football-live-score": [
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "Low Latency",
      "description": "Data is updated every 3-5 seconds, ensuring that users receive timely information during matches."
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "Live Match",
      "description": "The heart of your API lies in delivering live match information. It covers various aspects: Match Minute: Real-time updates on the current minute of the match."
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "Live Stream",
      "description": "This API also provides live stream of every single match."
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "League Coverage",
      "description": "Your API covers an impressive 2000+ soccer/football leagues worldwide. Whether it’s the English Premier League, La Liga, Serie A, Bundesliga, or international competitions, your users can access real-time updates for their favorite leagues."
    },
    {
      "img": "https://www.svgrepo.com/show/530440/machine-vision.svg",
      "heading": "Odds Score",
      "description": "This API also provides Odds Score and Historical Odds Score of every single match"
    },
    {
      "img": "https://www.svgrepo.com/show/530450/page-analysis.svg",
      "heading": "Integration Possibilities",
      "description": "Developers can seamlessly integrate your API into their websites, mobile apps, or other platforms. Use it to create a dedicated live scores section on sports websites or apps."
    },
    {
      "img": "https://www.svgrepo.com/show/530453/mail-reception.svg",
      "heading": "Player Stats",
      "description": "This API also provides player's up-to-date career stats"
    },
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "Team Rankings and Logos",
      "description": "Alongside live scores, your API also provides team rankings and access to team logos. Users can display team logos alongside match information for a visually appealing experience."
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "Free Trial Period",
      "description": "We offer a 14-day free trial so they can explore the API’s capabilities firsthand."
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "News",
      "description": "This API also provides latest and trending news as well."
    },
  ],

  "betting-api": [
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "200 ms Latency",
      "description": "The API has latency of 100 ms, ensuring that users receive timely information during matches."
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "Odds Score",
      "description": "The API offers Odds Score of sports like Cricket, Football Tennis and More"
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "Fancy Session",
      "description": "The API offers Fancy Score of sports like Cricket, Football Tennis and More"
    },
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "Bookmaker",
      "description": "The API offers Bookmaker of sports like Cricket, Football Tennis and More"
    },
    {
      "img": "https://www.svgrepo.com/show/530440/machine-vision.svg",
      "heading": "Line Market",
      "description": "The API offers Line Market of sports like Cricket, Football Tennis and More"
    },
    {
      "img": "https://www.svgrepo.com/show/530450/page-analysis.svg",
      "heading": "Casino",
      "description": "The API offers 30+ casino Games and their respected Odds Score and Live Stream and more"
    },
    {
      "img": "https://www.svgrepo.com/show/530453/mail-reception.svg",
      "heading": "Live Stream",
      "description": "This API offers Live Stream of every single sports"
    },

    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "Result",
      "description": "You will be able to get the result of the every snglematch"
    },

    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "Racing",
      "description": "It offers racing games as well. Do check it out on RapidAPI"
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "Special Event",
      "description": "We offer special events like Lok Sabha 2024 elections and many more. for more info please visit Docs."
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "24/7 Support",
      "description": "24/7 quick response on WhatsApp. What Are you waiting for?"
    },

  ],
  "diamond-betting-api": [
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "200 ms Latency",
      "description": "The API has latency of 100 ms, ensuring that users receive timely information during matches."
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "Odds Score",
      "description": "The API offers Odds Score of sports like Cricket, Football Tennis and More"
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "Fancy Session",
      "description": "The API offers Fancy Score of sports like Cricket, Football Tennis and More"
    },
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "Bookmaker",
      "description": "The API offers Bookmaker of sports like Cricket, Football Tennis and More"
    },
    {
      "img": "https://www.svgrepo.com/show/530440/machine-vision.svg",
      "heading": "Line Market",
      "description": "The API offers Line Market of sports like Cricket, Football Tennis and More"
    },
    {
      "img": "https://www.svgrepo.com/show/530450/page-analysis.svg",
      "heading": "Casino",
      "description": "The API offers 68 casino Games and their respected Odds Score and Live Stream and more"
    },
    {
      "img": "https://www.svgrepo.com/show/530453/mail-reception.svg",
      "heading": "Live Stream",
      "description": "This API offers Live Stream of every single sports"
    },

    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "Result",
      "description": "You will be able to get the result of the every sigle match"
    },

    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "Racing",
      "description": "It offers racing games as well. Do check it out on RapidAPI"
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "Special Event",
      "description": "We offer special events like Lok Sabha 2024 elections and many more. for more info please visit Docs."
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "24/7 Support",
      "description": "24/7 quick response on WhatsApp. What Are you waiting for?"
    }
  ],

  "all-sport-live-stream": [
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "100 ms Latency",
      "description": "The API has latency of 100 ms, ensuring that users receive timely information during matches."
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "No Delay",
      "description": "The API offers lowest latency of all Live Streams"
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "Multiple Servers",
      "description": "Our live stream servers are located in different parts of the world, ensuring uninterrupted streaming for your users."
    },
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "Football Live Stream",
      "description": "The API has live stream of every single Football match"
    },
    {
      "img": "https://www.svgrepo.com/show/530440/machine-vision.svg",
      "heading": "Cricket Live Stream",
      "description": "The API has live stream of every single Cricket match"
    },
    {
      "img": "https://www.svgrepo.com/show/530450/page-analysis.svg",
      "heading": "Tennis Live Stream",
      "description": "The API has live stream of every single Tennis match"
    },
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "Basketball",
      "description": "The API has live stream of every single Basketball match"
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "Ice Hockey",
      "description": "The API has live stream of every single Ice Hockey match"
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "120+ Other Sports",
      "description": "The API has live stream more than 120 sports. We have Live Stream of Cricket, Football, Tennis, Basketball, Volleyball, Hockey, Golf, Rugby, and many more."
    },

  ],

  "fantasy-cricket-api": [
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "100 ms Latency",
      "description": "The API has latency of 100 ms, ensuring that users receive timely information during matches."
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "No Delay",
      "description": "The API offers lowest latency of all matches"
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "Multiple Servers",
      "description": "Our live stream servers are located in different parts of the world, ensuring uninterrupted streaming for your users."
    },
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "All Sports",
      "description": "The API has fantasy data of 4 sports, cricket, football, kabbdadi and basketball"
    },
    {
      "img": "https://www.svgrepo.com/show/530440/machine-vision.svg",
      "heading": "Cricket Live Stream",
      "description": "The API has live stream of every single Cricket match"
    },
    {
      "img": "https://www.svgrepo.com/show/530450/page-analysis.svg",
      "heading": "Real Time Player Points",
      "description": "The API has real time player points of every single match."
    },
    {
      "img": "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      "heading": "Player List",
      "description": "The API has playes list along with their credits"
    },
    {
      "img": "https://www.svgrepo.com/show/530442/port-detection.svg",
      "heading": "Dream Team",
      "description": "The API has dream time of every single match"
    },
    {
      "img": "https://www.svgrepo.com/show/530444/availability.svg",
      "heading": "24/7 Support",
      "description": "24/7 quick response on WhatsApp. What Are you waiting for?"
    },

  ]
}

const APIPriceFeatures = {
  "cricket-live-score": [
    {
      "api_type": "Free",
      "price_usd": "0",
      "price_inr": "0",
      "button": "GET FREE",
      "link": "https://rapidapi.com/sudityakumar1/api/free-cricket-live-score1",
      "documentation": "https://rapidapi.com/sudityakumar1/api/free-cricket-live-score1",
      "avaialble_features": [
        "99 req/month", "1 req/second", "~100 ms Latency", "Live Score", "Live Stream", "Odds Score", "Series", "All Matches", "Player", "Schedule", "Rankings", "News"
      ],
      "missing_features": []
    },
    {
      "api_type": "Basic",
      "price_inr": '4,999',
      'price_usd': '50',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/sudityakumar1/api/free-cricket-live-score1",
      "link": "https://wa.me/+16616750322?text=hello",
      "avaialble_features": [
        "100,000 req/month", "1 req/second", "~100 ms Latency", "Live Score", "Series", "All Matches", "Player", "Schedule", "Rankings", "News"
      ],
      "missing_features": ["Live Stream", "Odds Score"]
    },
    {
      "api_type": "Premium",
      "price_inr": '6,999',
      'price_usd': '100',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/sudityakumar1/api/free-cricket-live-score1",
      "link": "https://wa.me/+16616750322?text=hello",
      "avaialble_features": [
        "unlimited req/month", "unlimited req/second", "~100 ms Latency", "Live Score", "Live Stream", "Odds Score", "Series", "All Matches", "Player", "Schedule", "Rankings", "News"
      ],
      "missing_features": []
    },
  ],
  "football-live-score": [
    {
      "api_type": "Free",
      "price_usd": "0",
      "price_inr": "0",
      "button": "GET FREE",
      "link": "https://rapidapi.com/sudityakumar1/api/free-football-live-score",
      "documentation": "https://rapidapi.com/sudityakumar1/api/free-football-live-score",
      "avaialble_features": [
        "99 req/month", "1 req/second", "~200 ms Latency", "Live Score", "Live Stream", "Odds Score", "Leagues", "All Matches", "Player", "Schedule", "Rankings", "News"
      ],
      "missing_features": []
    },
    {
      "api_type": "Basic",
      "price_inr": '4,999',
      'price_usd': '50',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/sudityakumar1/api/free-football-live-score",
      "link": "https://wa.me/+16616750322?text=hello",
      "avaialble_features": [
        "100,000 req/month", "1 req/second", "~200 ms Latency", "Live Score", "Odds Score", "Leagues", "All Matches", "Player", "Schedule", "Rankings", "News",
      ],
      "missing_features": ["Live Stream"]
    },
    {
      "api_type": "Premium",
      "price_inr": '6,999',
      'price_usd': '100',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/sudityakumar1/api/free-football-live-score",
      "link": "https://wa.me/+16616750322?text=hello",
      "avaialble_features": [
        "unlimited req/month", "unlimited req/second", "~200 ms Latency", "Live Score", "Live Stream", "Odds Score", "Leagues", "All Matches", "Player", "Schedule", "Rankings", "News"
      ],
      "missing_features": []
    },
  ],
  "betting-api": [
    {
      "api_type": "Free",
      "price_usd": "0",
      "price_inr": "0",
      "button": "GET FREE",
      "link": "https://rapidapi.com/scoreswift-scoreswift-default/api/betfair14",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/betfair14",
      "avaialble_features": [
        "99 req/month", "1 req/second", "~200 ms Latency", "Odds Score", "Bookmaker", "Session", "Result", "Racing", "Sports Live Stream", "Casino", "Casino Live Stream", "Casino Odds Score"
      ],
      "missing_features": []
    },
    {
      "api_type": "Basic",
      "price_inr": '7,000',
      'price_usd': '80',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/betfair14",
      "link": "https://wa.me/+16616750322?text=I+want+basic+plan+of+Befit+API",
      "avaialble_features": [
        "100,000 req/month", "1 req/second", "~200 ms Latency", "Odds Score", "Bookmaker", "Session", "Racing", "Casino", "Casino Odds Score", "Sports Live Stream"],
      "missing_features": ["Casino Live Stream", "Result"]
    },
    {
      "api_type": "Premium",
      "price_inr": '10,000',
      'price_usd': '120',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/betfair14",
      "link": "https://wa.me/+16616750322?text=I+want+premium+plan+of+Befit+API",
      "avaialble_features": [
        "Unlimited req/month", "Unlimited req/second", "~200 ms Latency", "Odds Score", "Bookmaker", "Session", "Result", "Racing", "Sports Live Stream", "Casino", "Casino Live Stream", "Casino Odds Score"
      ],
      "missing_features": []
    },
  ],

  "diamond-betting-api": [
    {
      "api_type": "Free",
      "price_usd": "0",
      "price_inr": "0",
      "button": "GET FREE",
      "link": "https://rapidapi.com/scoreswift-scoreswift-default/api/diamond-betting-api",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/diamond-betting-api",
      "avaialble_features": [
        "99 req/month", "1 req/second", "~200 ms Latency", "Odds Score", "Bookmaker", "Session", "Result", "Racing", "Sports Live Stream", "68 Casino", "Casino Live Stream", "Casino Odds Score"
      ],
      "missing_features": []
    },
    {
      "api_type": "Basic",
      "price_inr": '15,000',
      'price_usd': '150',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/diamond-betting-api",
      "link": "https://wa.me/+16616750322?text=I+want+basic+plan+of+Befit+API",
      "avaialble_features": [
        "100,000 req/month", "1 req/second", "~200 ms Latency", "Odds Score", "Bookmaker", "Session", "Racing", "68 Casino", "Casino Odds Score", "Sports Live Stream"],
      "missing_features": ["Casino Live Stream", "Result"]
    },
    {
      "api_type": "Premium",
      "price_inr": '20,000',
      'price_usd': '199',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/diamond-betting-api",
      "link": "https://wa.me/+16616750322?text=I+want+premium+plan+of+Befit+API",
      "avaialble_features": [
        "Unlimited req/month", "Unlimited req/second", "~200 ms Latency", "Odds Score", "Bookmaker", "Session", "Result", "Racing", "Sports Live Stream", "Casino", "Casino Live Stream", "Casino Odds Score"
      ],
      "missing_features": []
    },
  ],
  "all-sport-live-stream": [
    {
      "api_type": "Free",
      "price_usd": "0",
      "price_inr": "0",
      "button": "GET FREE",
      "link": "https://rapidapi.com/scoreswift-scoreswift-default/api/all-sport-live-stream",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/all-sport-live-stream",
      "avaialble_features": [
        "99 req/month", "1 req/second", "~100 ms Latency", "Multiple Servers", "Live Stream v1", "Live Stream v2", "Live Stream v3"
      ],
      "missing_features": []
    },
    {
      "api_type": "Basic",
      "price_inr": '4,999',
      'price_usd': '80',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/all-sport-live-stream",
      "link": "https://wa.me/+16616750322?text=I+want+basic+plan+of+Befit+API",
      "avaialble_features": [
        "100,000 req/month", "1 req/second", "~100 ms Latency", "Multiple Servers", "Live Stream v1"
      ],
      "missing_features": ["Live Stream v2", "Live Stream v3"]
    },
    {
      "api_type": "Premium",
      "price_inr": '6,999',
      'price_usd': '100',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/all-sport-live-stream",
      "link": "https://wa.me/+16616750322?text=I+want+premium+plan+of+Befit+API",
      "avaialble_features": [
        "Unlimited req/month", "Unlimited req/second", "~100 ms Latency", "Multiple Servers", "Live Stream v1", "Live Stream v2", "Live Stream v3"
      ],
      "missing_features": []
    },
  ],
  "fantasy-cricket-api": [
    {
      "api_type": "Free",
      "price_usd": "0",
      "price_inr": "0",
      "button": "GET FREE",
      "link": "https://rapidapi.com/scoreswift-scoreswift-default/api/fantasy-cricket-api",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/fantasy-cricket-api",
      "avaialble_features": [
        "99 req/month", "1 req/second", "~100 ms Latency", "Multiple Servers", "All Matches", "Players", "Dream Team", "Real Time Player Points", "Basic Info"
      ],
      "missing_features": []
    },
    {
      "api_type": "Basic",
      "price_inr": '7,000',
      'price_usd': '250',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/fantasy-cricket-api",
      "link": "https://wa.me/+16616750322?text=I+want+basic+plan+of+Befit+API",
      "avaialble_features": [
        "100,000 req/month", "1 req/second", "~100 ms Latency", "Multiple Servers", "All Matches", "Players", "Dream Team", "Real Time Player Points", "Basic Info"
      ],
      "missing_features": []
    },
    {
      "api_type": "Premium",
      "price_inr": '10,000',
      'price_usd': '500',
      "button": "GET NOW",
      "documentation": "https://rapidapi.com/scoreswift-scoreswift-default/api/fantasy-cricket-api",
      "link": "https://wa.me/+16616750322?text=I+want+premium+plan+of+Befit+API",
      "avaialble_features": [
        "Unlimited req/month", "Unlimited req/second", "~100 ms Latency", "Multiple Servers", "All Matches", "Players", "Dream Team", "Real Time Player Points", "Basic Info"
      ],
      "missing_features": []
    },
  ]
}

export { APIList, APIPriceFeatures, APIFeatures }
